import React, { useEffect, useState } from 'react'
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import './chatRoom.css';
import ajax from '../Services/fetchService';
import { useUser } from '../UserProvider';
import jwt_decode from "jwt-decode";

import { Button, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Formik } from "formik";
import FormTextField from '../FormUtil/form-field';
import FormFieldArray from '../FormUtil/form-field-array';

var stompClient = null;

const ChatRoom = ({ loggedInUser }) => {
    const [ topics, setTopics ] = useState(new Set());
    const [privateChats, setPrivateChats] = useState(new Map());  
    const [messages, setMessages] = useState(new Array()); 
    const [tab,setTab] =useState("INSTRUCTOR CHAT");
    const user = useUser();
    const [isConnected, setIsConnected] = useState(false);
    const decodedJwt = jwt_decode(user.jwt);
    const [stompClient, setStompClient] = useState(null);
    const [userData, setUserData] = useState({
        topic: '',
        sender: loggedInUser.name,
        senderEmail: decodedJwt.sub,
        recipient: '',
        connected: false,
        message: '',
        status: ''
      });
    useEffect(() => {
      setUserData({ ...userData, "sender" : loggedInUser.name })
    }, [loggedInUser]);

    useEffect(() => {
        ajax("api/messages/" + decodedJwt.sub, "GET", user.jwt).then((data) => {
            let topics = new Set();
            data.forEach(message => {
                topics.add(message.topicName);
            })

            setTopics(topics);
            setMessages(data)
        });
    }, []);

    useEffect(() => {
        console.log(topics)
    }, [topics])

    useEffect(() => {
        let sock = new SockJS(process.env.REACT_APP_API_URL + 'ws');
        let client = over(sock);
        setStompClient(client);
        client.connect();        
    }, [])

    useEffect(() => {
        setIsConnected(true);        
        setUserData({...userData,"connected": true});
        console.log(stompClient)
        if (decodedJwt.authorities.includes("ROLE_STUDENT")) {
            stompClient.subscribe('/instructorGroup/messages');
        } 
        // if (decodedJwt.authorities.includes("ROLE_INSTRUCTOR")) {
        //     stompClient.subscribe('/user/' + userData.sender + '/private', onPrivateMessage);
        // } 
        // var chatMessage = {
        //     sender: userData.sender,
        //     senderEmail: userData.senderEmail,
        //     status:"JOIN"
        //   };
        //   if (stompClient != null && stompClient.connected == true) {
        //     stompClient.send("/app/instructor-group-message", { status: "JOIN"}, JSON.stringify(chatMessage));
        //   }
          
    }, [stompClient])

    

    // const onMessageReceived = (payload)=>{
    //     var payloadData = JSON.parse(payload.body);
    //     console.log(payload.data)
    //     switch(payloadData.status){
    //         // case "JOIN":
    //         //     if(!privateChats.get(payloadData.sender)){
    //         //         privateChats.set(payloadData.sender,[]);
    //         //         setPrivateChats(new Map(privateChats));
    //         //     }
    //         //     break;
    //         case "MESSAGE":
    //             messages.push(payloadData);
    //             setMessages([...messages]);
    //             break;
    //     }
    // }
    
    // const onPrivateMessage = (payload)=>{
    //     console.log(payload);
    //     var payloadData = JSON.parse(payload.body);
    //     if(privateChats.get(payloadData.sender)){
    //         privateChats.get(payloadData.sender).push(payloadData);
    //         setPrivateChats(new Map(privateChats));
    //     }else{
    //         let list =[];
    //         list.push(payloadData);
    //         privateChats.set(payloadData.sender,list);
    //         setPrivateChats(new Map(privateChats));
    //     }
    // }

    const onError = (err) => {
        console.log(err);        
    }

    const handleTopicAdd = (event) => {
        let newTopics = topics;
        newTopics.add(event.value);
        setTopics(newTopics);
    }

    const handleMessage =(event)=>{
        const {value}=event.target;
        setUserData({...userData,"message": value});
    }
    const sendMessage=()=>{
            if (stompClient) {
              var chatMessage = {
                sender: userData.sender,
                senderEmail: userData.senderEmail,
                message: userData.message,
                topic: userData.topic, // what is the problem your trying to solve?
                status:"MESSAGE"
              };
              console.log(chatMessage);
              stompClient.send("/app/instructor-group-message", {}, JSON.stringify(chatMessage));
              setUserData({...userData,"message": ""});
            }
    }

    const sendPrivateValue=()=>{
        if (stompClient) {
          var chatMessage = {
            sender: userData.sender,
            recipient: tab,
            message: userData.message,
            status:"MESSAGE",
            topic: userData.topic
          };

          stompClient.send("/app/private-message", {}, JSON.stringify(chatMessage));
          setUserData({...userData,"message": ""});
        }
    }

    const handleTopic=(event)=>{
        const {value}=event.target;
        setUserData({...userData,"topic": value});
    }

    // const registerTopicAndConnect=()=>{
    //     connect();
    // }
    return (
    <div className="container">
        <div className="chat-box">
            <div className="member-list">
                <ul>
                    <li onClick={()=>{setTab("INSTRUCTOR CHAT")}} className={`member ${tab==="INSTRUCTOR CHAT" && "active"}`}>INSTRUCTOR CHAT</li>
                    {[...topics].map((topicName, index)=>{
                        return <li onClick={()=>{setTab(topicName)}} className={`member ${tab===topicName && "active"}`} key={index}>{topicName}</li>
                    })}
   
                </ul>
            </div>
            {tab==="INSTRUCTOR CHAT" && <div className="chat-content">
                <ul className="chat-messages">
                    {messages.map((chat,index)=>(
                        <li className={`message ${chat.sender === userData.sender && "self"}`} key={index}>
                            {chat.sender !== userData.sender && <div className="avatar">{chat.sender}</div>}
                            <div className="message-data">{chat.message}</div>
                            {chat.sender === userData.sender && <div className="avatar self">{chat.sender}</div>}
                        </li>
                    ))}
                </ul>

                <div className="send-message">
                    <input type="text" className="input-message" placeholder="enter the message" value={userData.message} onChange={handleMessage} /> 
                    <button type="button" className="send-button" onClick={sendMessage}>send</button>
                </div>
            </div>}
            {tab!=="INSTRUCTOR CHAT" && <div className="chat-content">
                <ul className="chat-messages">
                    {topics != null ? [...messages.filter((message) => message.topicName == tab)].map((chat,index)=>(
                        <li className={`message ${chat.sender === userData.sender && "self"}`} key={index}>
                            {chat.sender !== userData.sender && <div className="avatar">{chat.sender}</div>}
                            <div className="message-data">{chat.message}</div>
                            {chat.sender === userData.sender && <div className="avatar self">{chat.sender}</div>}
                        </li>
                    )) : ""}
                </ul>

                <div className="send-message">
                    <input type="text" className="input-message" placeholder="enter the message" value={userData.message} onChange={handleMessage} /> 
                    <button type="button" className="send-button" onClick={sendPrivateValue}>send</button>
                </div>
            </div>}
        </div>
    </div>
    )
}

export default ChatRoom